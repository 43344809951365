import React from 'react';
import { SlideShowSlideProps } from '../SlideShowSlide.types';
import MeshContainer from '../../../thunderbolt-core-components/MeshContainer/viewer/MeshContainer';
import FillLayers from '../../FillLayers/viewer/FillLayers';
import { useVideoAPI } from '../../../core/corvid/media/backgroundUtils';
import { MediaContainerVideoAPI } from '../../MediaContainers/MediaContainer/MediaContainer.types';
import styles from './style/SlideShowSlide.scss';

const SlideShowSlide: React.ForwardRefRenderFunction<
  MediaContainerVideoAPI,
  SlideShowSlideProps
> = (
  {
    id,
    fillLayers,
    children,
    skin,
    meshProps,
    onMouseEnter,
    onMouseLeave,
    getPlaceholder,
    onStop,
  },
  compRef,
) => {
  const MeshContainerWithChildren = () => (
    <MeshContainer id={id} {...meshProps}>
      {children}
    </MeshContainer>
  );

  const hasVideo = !!fillLayers.video;
  const videoRef = useVideoAPI(compRef, hasVideo, onStop);

  return (
    <div
      id={id}
      className={styles.SlideShowSlide}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <FillLayers
        {...fillLayers}
        extraClass={styles.bg}
        getPlaceholder={getPlaceholder}
        videoRef={videoRef}
      />
      <div className={styles.borderNode} />
      {skin === 'StateBoxStateFormSkin' ? (
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <MeshContainerWithChildren />
        </form>
      ) : (
        <MeshContainerWithChildren />
      )}
    </div>
  );
};

export default React.forwardRef(SlideShowSlide);
